@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Fugaz+One|Rubik');

$primary: #a64f66;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
    font-family: 'Rubik', sans-serif;
}

h1,h2,h3,h4 {
    font-family: 'Fugaz One', cursive;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 17px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin-top: 9px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $secondary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: $secondary;
        color: lighten($wht, 10%);
        text-decoration: none;
        box-shadow: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 150px;
    }
    .navbar-header a {
        float: left;
    }
}

.top-pad {
    padding: 0.5em;
    background: white;  
    font-family: 'Rubik', sans-serif;
    p {
        font-family: 'Rubik', sans-serif;
    }
    li {
        font-family: 'Rubik', sans-serif;
    }
    h1,h2,h3,h4 {
        @include fluid-type($min_width, $max_width, 18px, 30px);
        font-family: 'Fugaz One', cursive;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 0.9rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.noPad {
    padding: 0;
}

.mt {
    position: relative;
    top: -50px;
}
.mt2 {
    top: -100px;
}
.moveRight {
    position: relative;
    right: -50px;
}

.moveLeft {
    position: relative;
    left: -50px;
}

.txtBox {
    padding: 4rem 5rem 5rem 5rem;
    h1 {
        color: $primary;
    }
    @media (max-width: 991px) {
        padding: 2rem;
    }
}

.row {
    position: relative;
    img {
        position: relative;
        z-index: 1;
    }
    .box1 {
        position: absolute;
        border: 10px solid #d73e66;
        height: 75%;
        width: 50%;
        right: 56%;
        top: 50%;
        transform: translateY(-50%);
    }
    .box2 {
        position: absolute;
        border: 10px solid #d73e66;
        height: 75%;
        width: 50%;
        left: 53.5%;
        top: 50%;
        transform: translateY(-50%); 
    }
}

.bg {
    background: $primary;
}

.banner {
    background: linear-gradient(
         rgba($primary, 0.35),
         rgba($primary, 0.5)
        ),url(../img/banner.jpg) no-repeat;
    background-size: cover;
    padding: 18rem 0;
    background-position: 50% 20%;
    h1 {
        color: white;
        @include fluid-type($min_width, $max_width, 18px, 55px);
    }
    @media (max-width: 991px) {
        padding: 10rem 0;
    }
    @media (max-width: 767px) {
        padding: 8rem 0;
    }
}

.margTopBtm {  
    @media (min-width: 991px) {
       margin-top: 100px; 
    }
}

.bg {
    background: $primary;
    color: white;
    padding: 2rem 0;
}

.bg2 {
    padding: 3rem 0;
    background: $primary;
    color: white;
}
a.btn {
    @include btn;
}

@media (max-width: 991px) {
    .sideImg {
        max-width: 400px;
        width: 100%;
        display: block;
        margin: 1rem auto;
    }
    .mt,.mt2 {
        top: 0;
    }
}

@media (max-width: 767px) {
    .box1, .box2 {
        display: none;
    }
}

.padTopBtm {
    padding: 4rem 15px;
    span {
        font-size: 2rem;
        color: $primary;
    }
    h1 {
        font-size: 1.5rem;
        color: $primary;
    }
    @media (max-width: 600px) {
        .col-xs-4.fullWidth {
            width: 100%;
        }
    }
}

a {
    color: $primary;
    &:hover {
        text-decoration: none;
        color: $primary;
    }
    &:focus {
        text-decoration: none;
        color: $primary;
    }
}